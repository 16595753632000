import React from "react"
import Page from "../components/page"
import pageStyles from "../components/page.module.css"

export default function ErrorPage({ location }) {
  return (
    <Page title={"Page not found - Phototailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <h1>WE ARE
            SORRY</h1>
          <br/>
          <br/>
          <p>The page you are looking for does not exist. It may have been moved or deleted.</p>
          <br/>
          <br/>
          <div style={{height:'40vh'}}></div>
        </div>
      </div>
    </Page>
  )
}